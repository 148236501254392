@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
.pcnone {
  display: none;
}

@media only screen and (max-width: 835px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 400;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1000px;
}

.inner2 {
  margin: 0 auto;
  max-width: 800px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1000px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1000px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 835px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  padding: 1rem 2rem;
  max-width: 100% !important;
  margin: 0 auto;
  position: fixed;
  z-index: 11;
  top: 0;
  width: 100%;
  background: rgba(245, 174, 240, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  .fixed {
    padding: 10px;
  }
}

.fixed .subnav {
  display: block !important;
}

.fixed .wrap {
  width: 60%;
  max-width: auto !important;
  margin: auto !important;
}

.fixed .tel2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subnav {
  display: none;
}

@media only screen and (max-width: 835px) {
  .subnav {
    display: block;
  }
}

.subnav#siteID2 {
  width: auto;
}

@media only screen and (max-width: 835px) {
  .subnav#siteID2 {
    width: 40%;
    line-height: 1;
  }
}

.subnav#siteID2 a {
  text-align: center;
  display: block;
}

.subnav#siteID2 img {
  width: 100% !important;
  max-width: calc(458px / 3);
}

@media only screen and (max-width: 835px) {
  .subnav#siteID2 img {
    max-width: 80%;
  }
}

.subnav.tel2 {
  width: auto;
  color: #fff;
  letter-spacing: 0.1em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  z-index: 9;
  font-size: 17px;
  font-size: 1.0625vw;
}

@media only screen and (min-width: 1600px) {
  .subnav.tel2 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 835px) {
  .subnav.tel2 {
    font-size: 17px;
  }
}

.subnav.tel2 .wrap2 {
  background: #41210f;
  padding: 10px 25px;
}

@media only screen and (max-width: 835px) {
  .subnav.tel2 .wrap2 {
    padding: 0 20px 1px;
  }
}

.subnav.tel2 a {
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subnav.tel2 span {
  margin-top: 7px;
  font-size: 27px;
  font-size: 1.6875vw;
}

@media only screen and (min-width: 1600px) {
  .subnav.tel2 span {
    font-size: 27px;
  }
}

@media only screen and (max-width: 835px) {
  .subnav.tel2 span {
    font-size: 24px;
  }
}

#header {
  position: relative;
  display: flex;
  justify-content: center;
}

#header #siteID {
  z-index: 1;
  top: 30px;
  position: relative;
}

@media only screen and (max-width: 835px) {
  #header #siteID {
    display: none;
  }
}

#header #siteID a {
  text-align: left;
  display: block;
}

#header #siteID img {
  width: 100% !important;
  max-width: calc(220px / 2);
}

@media only screen and (max-width: 835px) {
  #header #siteID img {
    max-width: 100%;
  }
}

#header .tel2 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  color: #fff;
  letter-spacing: 0.1em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  z-index: 12;
  font-size: 17px;
  font-size: 1.0625vw;
}

@media only screen and (min-width: 1600px) {
  #header .tel2 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 835px) {
  #header .tel2 {
    display: none;
  }
}

#header .tel2 .wrap2 {
  background: #41210f;
  padding: 10px 25px;
}

#header .tel2 a {
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 18px;
}

#header .tel2 span {
  margin-top: 7px;
  font-size: 27px;
  font-size: 1.6875vw;
}

@media only screen and (min-width: 1600px) {
  #header .tel2 span {
    font-size: 27px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  #header .tel2 span {
    font-size: 24px;
  }
}

@media only screen and (max-width: 640px) {
  #header .tel2 span {
    font-size: 20px;
  }
}

#hednav .wrap {
  max-width: 1000px;
  margin: 0 auto;
}

#hednav .wrap #nav_global {
  z-index: 10;
  position: relative;
}

#hednav .wrap #nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#hednav .wrap #nav_global ul li {
  position: relative;
  flex-grow: 1;
}

@media only screen and (max-width: 835px) {
  #hednav .wrap #nav_global ul li {
    height: auto !important;
  }
}

#hednav .wrap #nav_global ul li a {
  line-height: 1;
  display: block;
  color: #333 !important;
  padding: 30px 10px;
  font-size: 16px;
  font-size: 1vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .wrap #nav_global ul li a {
    font-size: 16px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  #hednav .wrap #nav_global ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  #hednav .wrap #nav_global ul li a {
    font-size: 16px;
  }
}

#hednav .wrap #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#hednav .wrap #nav_global ul li ul {
  position: absolute;
  display: block;
  left: -40px;
  width: 200px;
  background: rgba(245, 174, 240, 0.7);
}

@media only screen and (max-width: 835px) {
  #hednav .wrap #nav_global ul li ul {
    position: static;
    display: block !important;
    width: auto;
  }
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 835px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 15px;
    top: 15px;
    color: #333;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 835px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 835px) {
  #hednav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #fff;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info span {
    line-height: 1;
    display: block;
    margin-top: 10px;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: #1b4996;
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    background: #376eca;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global ul li > a {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 70px;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 0.8rem !important;
  }
  #hednav .wrap .navwrap #nav_global ul li > a::before {
    display: block;
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 pro";
    color: #f5aef0;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a::before {
    display: none;
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f015";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(2) a::before {
    left: 18px;
    content: "\f004";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(3) a::before {
    left: 19px;
    content: "\f002";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(4) a::before {
    left: 20px;
    content: "\f187";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(5) a::before {
    left: 21px;
    content: "\f143";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(6) a::before {
    left: 17px;
    content: "\f495";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(7) a::before {
    left: 23px;
    content: "\f3c5";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(8) a::before {
    left: 20px;
    content: "\f0e0";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  position: relative;
  z-index: 0;
  width: 100%;
}

#mainArea .maintxt {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainArea .maintxt h1 {
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-size: 70px;
  font-size: 4.375vw;
}

@media only screen and (min-width: 1600px) {
  #mainArea .maintxt h1 {
    font-size: 70px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  #mainArea .maintxt h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .maintxt h1 {
    font-size: 35px;
  }
}

#mainArea .maintxt h1 span {
  display: block;
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main_1.jpg);
  background-position: center;
}

@media only screen and (max-width: 835px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  z-index: 1;
  position: relative;
  color: #fff;
  letter-spacing: 0.1em;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-size: 60px;
  font-size: 3.75vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 {
    font-size: 60px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  #local-keyvisual h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 45px;
  }
}

#local-keyvisual h1 span {
  background-position: center;
  background-size: cover;
  padding: 200px 0;
  display: block;
}

@media only screen and (max-width: 1000px) {
  #local-keyvisual h1 span {
    padding: 100px 10px;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  margin: 50px 0;
}

@media only screen and (max-width: 835px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #fff;
}

footer#global_footer a {
  color: #fff;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer2 {
  border-bottom: 2px solid #f5aef0;
  background: url(../images/common/cont_bg.jpg) center top no-repeat;
  background-size: cover;
  padding: 100px 10px;
}

@media only screen and (max-width: 1000px) {
  #footer2 {
    padding: 50px 10px;
  }
}

@media only screen and (max-width: 835px) {
  #footer2 {
    padding: 30px 10px;
  }
}

#footer2 .inner2 {
  padding: 50px 0;
  background: rgba(255, 255, 255, 0.7);
}

#footer2 .inner2 dl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

#footer2 .inner2 dl dt {
  background: url(../images/common/pt.png);
  margin-bottom: 40px;
  line-height: 1.7em;
  display: block;
  width: 100%;
  padding: 25px 10px;
  font-weight: 700;
  color: #fff;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  #footer2 .inner2 dl dt {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  #footer2 .inner2 dl dt {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 .inner2 dl dt {
    font-size: 18px;
  }
}

#footer2 .inner2 dl dd {
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 835px) {
  #footer2 .inner2 dl dd {
    width: 70%;
  }
}

#footer2 .inner2 dl dd a {
  display: block;
  opacity: 1 !important;
}

#footer2 .inner2 dl dd a:hover {
  opacity: 0.7 !important;
}

#footer2 .inner2 dl dd a img {
  opacity: 1 !important;
}

#footer {
  margin-top: 1px;
  position: relative;
  background: url(../images/common/pt.png);
  padding: 50px 0;
}

@media only screen and (max-width: 1000px) {
  #footer {
    padding: 50px 10px;
  }
}

@media only screen and (max-width: 835px) {
  #footer {
    padding: 30px 10px;
  }
}

#footer .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 835px) {
  #footer .inner {
    flex-direction: column;
    justify-content: center;
  }
}

#footer .inner .flxL {
  text-align: left;
  width: 48%;
}

@media only screen and (max-width: 835px) {
  #footer .inner .flxL {
    width: auto;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 835px) {
  #footer .inner .flxL {
    width: 100%;
    text-align: center;
  }
}

#footer .inner .flxL #siteID {
  margin-bottom: 30px;
}

#footer .inner .flxL #siteID img {
  opacity: 1 !important;
  width: 100% !important;
  max-width: calc( 580px / 2);
}

#footer .inner .flxR {
  width: 48%;
  text-align: left;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 835px) {
  #footer .inner .flxR {
    width: auto;
  }
}

@media only screen and (max-width: 835px) {
  #footer .inner .flxR {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 835px) {
  #footer .inner .flxR #nav_footer {
    display: none;
  }
}

#footer .inner .flxR #nav_footer ul {
  float: left;
  margin-left: 15px;
}

#copy {
  background: url(../images/common/pt.png);
  margin-top: 1px;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  display: block;
  line-height: 1.7em;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    right: 10px;
    bottom: 140px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
  box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.1);
  border-radius: 50%;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #f5aef0;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #1f2029;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #1f2029;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes cont_1txt {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes cont_1txt {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cont_1 {
  font-size: 16px;
  position: relative;
  overflow: hidden;
  background: url(../images/common/pt.png);
  margin-bottom: 100px;
  padding: 150px 10px;
}

@media only screen and (max-width: 1000px) {
  .cont_1 {
    padding: 100px 10px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 835px) {
  .cont_1 {
    padding: 50px 10px;
  }
}

.cont_1 .txt {
  color: #fff;
}

.cont_1 .txt div {
  opacity: 0;
}

@media only screen and (max-width: 835px) {
  .cont_1 .txt div {
    opacity: 1;
  }
}

.cont_1 .txt div.on {
  opacity: 1 !important;
  animation: cont_1txt 2s ease 0s 1 normal;
  -webkit-animation: cont_1txt 2s ease 0s 1 normal;
}

.cont_1 .txt h2 {
  opacity: 0;
}

@media only screen and (max-width: 835px) {
  .cont_1 .txt h2 {
    opacity: 1;
  }
}

.cont_1 .txt h2.on {
  opacity: 1 !important;
}

.cont_1 .circle2 i {
  opacity: 0;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: 2s cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: 2s cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: 2s cubic-bezier(0.86, 0, 0.07, 1);
  transition: 2s cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 0;
}

@media only screen and (max-width: 835px) {
  .cont_1 .circle2 i {
    opacity: 0.5;
  }
}

.active .circle2 i {
  opacity: .5;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.active .circle2 i:nth-child(2) {
  -moz-transition-delay: .2s;
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  -ms-transition-delay: .2s;
  transition-delay: .2s;
}

.active .circle2 i:nth-child(3) {
  -moz-transition-delay: .3s;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  -ms-transition-delay: .3s;
  transition-delay: .3s;
}

.cont_1 .circle2 {
  position: absolute;
  z-index: 0;
  right: -600px;
  top: 0;
}

@media only screen and (max-width: 769px) {
  .cont_1 .circle2 {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@media only screen and (min-width: 769px) {
  .cont_1 .circle2 {
    top: -420px;
  }
}

@media only screen and (max-width: 769px) {
  .cont_1 .circle2 {
    top: -330px;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}

.cont_1 .circle2 i {
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  border-radius: 50%;
}

.cont_1 .circle2 i:nth-child(1) {
  width: 1200px;
  height: 1200px;
  border: 110px solid #fff;
}

.cont_1 .circle2 i:nth-child(2) {
  width: 780px;
  height: 780px;
  border: 51px solid #fff;
  content: '';
  position: absolute;
  top: 210px;
  left: 210px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.cont_1 .circle2 i:nth-child(3) {
  width: 480px;
  height: 480px;
  border: 120px solid #fff;
  content: '';
  position: absolute;
  top: 360px;
  left: 360px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.bnr_1 {
  max-width: 1000px;
  margin: 0 auto;
  justify-content: space-between !important;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .bnr_1 {
    padding: 0 10px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .bnr_1 {
    margin-bottom: 0;
  }
}

.bnr_1 .box {
  position: relative;
  width: 48% !important;
}

@media only screen and (max-width: 640px) {
  .bnr_1 .box {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.bnr_1 .box a {
  opacity: 1 !important;
}

.bnr_1 .box a:hover div {
  opacity: 1 !important;
}

.bnr_1 .box a:hover div img {
  transition: 2s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.bnr_1 .box a article {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bnr_1 .box a article > img {
  margin-left: 20px;
  opacity: 1 !important;
  position: absolute;
  z-index: 1;
}

.bnr_1 .box a article div img {
  transition: 2s;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1 !important;
  width: 100% !important;
}

.bnr_2 {
  max-width: 1000px;
  margin: 0 auto;
  justify-content: space-between !important;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1000px) {
  .bnr_2 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1000px) {
  .bnr_2 {
    padding: 0 10px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .bnr_2 {
    margin-bottom: 30px;
  }
}

.bnr_2 .box {
  position: relative;
  width: 32% !important;
}

@media only screen and (max-width: 640px) {
  .bnr_2 .box {
    width: 48% !important;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .bnr_2 .box:nth-child(3) {
    width: 100% !important;
  }
}

.bnr_2 .box a {
  opacity: 1 !important;
}

.bnr_2 .box a:hover img {
  transition: 2s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.bnr_2 .box a article {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.bnr_2 .box a article > img {
  transition: 2s;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1 !important;
  width: 100% !important;
}

.bnr_2 .box a article div {
  position: absolute;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 20px;
  color: #fff;
  bottom: 20px;
  right: 20px;
}

.newswrap {
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1000px) {
  .newswrap {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1000px) {
  .newswrap {
    padding: 0 10px;
    margin-bottom: 70px;
  }
}

.newswrap .news {
  padding-right: 10px;
  height: 350px;
  overflow-y: scroll;
  border: 0;
  display: block !important;
}

.newswrap .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
}

.newswrap .news dl:first-child {
  padding: 0 0 15px;
}

.newswrap .news dl:last-child {
  border-bottom: 1px dashed #333;
}

.newswrap .news dl .blog_photo {
  display: none;
}

.newswrap .news dl dt {
  border: 0 !important;
  background: inherit;
  padding: 0;
  font-size: 16px;
  width: 100%;
  display: block;
}

.newswrap .news dl dd {
  padding: 0 !important;
  border: 0 !important;
  background: inherit;
}

/* title */
.midashi h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.7em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 40px;
  font-size: 2.5vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .midashi h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 25px;
  }
}

.midashi2 h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.7em;
  color: #41210f;
  font-weight: 700;
  font-size: 33px;
  font-size: 2.0625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 33px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi3 h2 {
  color: #000;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

.midashi3 h3 {
  color: #000;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h3 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .midashi3 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h3 {
    font-size: 20px;
  }
}

.midashi4 h2 {
  letter-spacing: 0.2em;
  padding: 15px 10px;
  font-weight: 600;
  color: #333;
  background: #f4f4f4;
  border-left: solid 5px #f5aef0;
  border-bottom: solid 3px #d7d7d7;
  font-size: 23px;
  font-size: 1.4375vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h2 {
    font-size: 23px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h2 {
    font-size: 18px;
  }
}

.midashi4 h3 {
  letter-spacing: 0.2em;
  padding: 20px 15px;
  font-weight: 700;
  color: #333;
  background: #f4f4f4;
  border-left: solid 5px #f5aef0;
  border-bottom: solid 3px #d7d7d7;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h3 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .midashi4 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h3 {
    font-size: 18px;
  }
}

.items2 {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.38164%;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .items2 {
    margin-left: -3.38164%;
  }
}

@media only screen and (max-width: 640px) {
  .items2 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.items2 .box {
  border-bottom: 0;
  width: 29.95169%;
  height: 29.95169%;
  margin-left: 3.38164%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .items2 .box {
    width: 29.95169%;
    margin-left: 3.38164%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .items2 .box {
    width: 48%;
    margin-left: 0;
  }
}

.items3 {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .items3 {
    display: block;
  }
}

.items3 .flxL {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .items3 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.items3 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .items3 .flxR {
    width: 100%;
    margin-left: 0;
  }
}

.btn {
  max-width: 300px;
}

.btn a {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  color: #fff;
  background: #41210f;
  padding: 20px 25px;
  font-size: 17px;
  font-size: 1.0625vw;
}

@media only screen and (min-width: 1600px) {
  .btn a {
    font-size: 17px;
  }
}

@media only screen and (max-width: 835px) {
  .btn a {
    font-size: 17px;
  }
}

.faq_q {
  border: none !important;
}

.faq_q > div {
  font-size: 16px;
  border-bottom: 1px #1b4996 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #1b4996;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq_q > div:before {
  content: "Q";
  display: block;
  background: #1b4996;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -3px;
}

.faq_a {
  border: none !important;
}

.faq_a > div {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #376eca solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq_a > div:before {
  content: "A";
  display: block;
  background: #376eca;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -6px;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.waku {
  padding: 40px;
  background: whitesmoke;
  border: double 7px #1b4996;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.comp dl {
  border-bottom: 1px solid #ccc;
}

.comp dl:nth-child(1) {
  border-top: 1px solid #ccc;
}

.comp dl dt {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl dd {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl a {
  color: #b2b2b2;
}

@media only screen and (max-width: 640px) {
  .comp2 dl {
    text-align: center;
    display: block !important;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dt {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
}

.comp2 dl dd {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dd {
    width: 100% !important;
  }
}

.comp2 dl a {
  color: #b2b2b2;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 835px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}
